
import { get, getWithHeader, post } from "@/api/axios";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IExaQAItem, IExaTask } from "@/state/models";
import EntityPropertiesEdit from "@/components/EntityPropertiesEdit.vue";
//import VueUploadWrapper from "vue-upload-wrapper";
import TableRootEntities from "@/components/TableRootEntities.vue";
import saveAs from "file-saver";
import config from "@/config";
import { upload } from "@/api/upload.api";
//import FileUpload from 'v-file-upload'

const FileUpload = require("v-file-upload");
Vue.component("FileUpload", FileUpload);

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

@Component({
  components: {
    EntityPropertiesEdit,
    TableRootEntities
    /*,
    VueUploadWrapper*/
  }
})
export default class ExaQuestionListView extends Vue {
  questions: IExaQAItem[] = [];
  questions_search: string = "";
  CONFIG: any = config.data();

  toggleContextMenu: boolean = false; // open/close system menu

  tasks_headers: Array<any> = [
    {
      text: "Вопрос",
      align: "start",
      value: "question"
    },
    {
      text: "",
      align: "end",
      value: "actions"
    }
  ];

  navi_breadcrumbs: Array<any> = [
    {
      text: "Главная",
      exact: true,
      to: {
        name: "today",
        params: {}
      }
    },
    {
      text: "Библиотека вопросов",
      link: false
    }
  ];

  dialog = {
    isActive: false,
    text: ""
  };

  handleFileInput = (evt: Event) => {
    console.log("handleFileInput");
    //console.log((evt.target as HTMLInputElement).value);
  };

  onFilePicked = (payload: Event) => {
    console.log("asdf");
  };

  onFileChange(event: HTMLInputEvent | DragEvent) {
    console.log("onFileChange");
  }

  uploadQuestionsFile(e: Event) {
    console.log("uploadQuestionsFile");

    let files = (e as HTMLInputEvent).target.files;
    if (!files || !files.length) return;
    console.log(files[0]);

    // handle file changes
    const formData = new FormData();

    // append the files to FormData
    Array.from(Array(files.length).keys()).map(x => {
      if (files && files[x]) {
        formData.append("files", files[x]);
      }
    });

    // upload the files
    post(`/api/@examination/exa/import`, formData)
      .then(x => {
        console.log(x);
        //this.$store.dispatch("exa/refreshQuestions");
      })
      .catch(err => {
        console.log(err);
      });
  }

  created() {
    //debugger;
    console.log("ExaQuestionListView created");
    get(`/api/@examination/exa/question`).then(x => {
      this.questions = x.data;
      console.log("Success fetch your tasks", this.questions);
    });
  }

  newItem() {
    // examination/question/:id/edit
    post(`/api/@examination/exa/question`, { question: this.dialog.text })
      .then(x => {
        console.log("Success add new question", x.data);

        this.$router.push({
          name: "examinationQuestionView",
          params: { id: x.data._id },
          query: { editMode: true } as any
        });
      })
      .catch(x => {
        console.error(x);
        this.dialog.isActive = false;
      });
  }

  onFileChangeEvent(f: any) {
    console.log("onFileChange");
    console.log(f);
  }

  exportAllQuestions() {
    get(`/api/@examination/exa/export`)
      .then(blob => {
        console.log("Exported raw data is", blob);
        saveAs(
          new Blob([JSON.stringify(blob.data)], { type: "application/json" }),
          "exa-questions.json"
        );
      })
      .catch(x => {
        console.error(x);
        this.dialog.isActive = false;
      });
  }

  importQuestions(fieldName: any, asdf: any) {
    console.log("Importing questions");
    // const formData = new FormData();
    // if (!fileList.length) return;
    // Array.from(Array(fileList.length).keys()).map(x => {
    //   formData.append(fieldName, fileList[x], fileList[x].name);
    // });
    // // add owner id
    // // eslint-disable-next-line no-console
    // console.log("asdfasdfasdf");
    // formData.append("obj", this.$route.params.id);
    // // eslint-disable-next-line no-console
    // console.log("asdfasdfasdf!");
    // // save it
    // this.save(formData);
  }

  // onUploadFiles: function (event: Event) {
  //   console.log("onUploadFiles");
  //   const files: ReadonlyArray<File> = [
  //     ...(this.upload.files ? this.upload.files : [])
  //   ];

  //   files.forEach(file => {
  //     console.log(`File: ${file.name}`);
  //   });
  // }

  /** Upload element */
  get upload(): HTMLInputElement {
    return this.$refs.uplasdfsadfasdoad as HTMLInputElement;
  }

  goTo(item: IExaQAItem) {
    this.$router.push({
      name: "examinationQuestionView",
      params: { id: item._id }
    });
  }
}
